import React from 'react';
import '../App.css';
import logo from '../assets/GT-Logo.png';
import albumArtwork from '../assets/C\'EST LA VIE ARTWORK IDEA.png';

function Home() {
  return (
<div className="home-container2">
  <img src={logo} alt="Globetrotters Logo" className="logo" />

  <h1> By Kaleab Samuel & Paris Williams</h1>
  <h1> Out Now!</h1>
  <p></p>

  
  <div className="spotify-embed-container">
    <iframe 
      className="spotify-iframe"
      src="https://open.spotify.com/embed/album/3pVRpSRh08WS6Ax1UaEnbT?utm_source=generator&theme=0&view=coverart"
      frameBorder="0" 
      allowFullScreen
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
      loading="lazy"
    />
  </div>

  <section>

  <div className="home-container2">
  <h2>Paris Williams Announces New Album With The Humans</h2>

  <h3>"A Paris Williams Joint" 11/19/24</h3>
 <p></p>
  <p1>"Alien Luv Feat Saving Miles Lemon"</p1>
  <p></p>
   <p></p>
  <p1>"A Paris Williams Joint Feat TheBabeGabe & Sintoria"</p1>
   <p></p>
    <p></p>
  <div className="spotify-embed-container">
    <iframe 
      style={{borderRadius: "12px"}} 
      src="https://open.spotify.com/embed/album/4DS9PWAMaQOjHB1CEIkzT0?utm_source=generator" 
      width="100%" 
      height="352" 
      frameBorder="0" 
      allowFullScreen="" 
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
      loading="lazy"
    />
  </div>

  </div>

  </section>

     
      <h1>01. C'est La Vie</h1>
      <p><strong>Produced By</strong> Paris Williams & Kaleab Samuel</p>
      <p><strong>Mixed and Mastered By</strong> Luke Petet</p>

      <div className="video-container2">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/BcUqJpzW3qs"
          title="C'est La Vie"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>

   
        {/* Rest of the tracks */}
        <div className="space-y-12 w-full">
          {/* Track 2 */}
          <section>
            <h1>02. Girl Like U</h1>
            <p><strong>Produced By</strong> Paris Williams</p>
            <p><strong>Additional Production by</strong> Kaleab Samuel</p>
            <p><strong>Mixed and Mastered By</strong> Luke Petet</p>

            <div className="video-container2">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/YN0jeYHrVU8"
                title="Girl Like U"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </section>

          {/* Track 3 */}
          <section>
            <h1>03. Rapunzel (snippet)</h1>
            <p><strong>Produced By</strong> Paris Williams & Kaleab Samuel</p>
            <p><strong>Mixed and Mastered By</strong> Luke Petet</p>

            <div className="video-container2">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/joFfzx1gNS4?si=509yqD_yz6Na9zMS"
                title="Rapunzel"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </section>

          {/* Track 4 */}
          <section>
            <h1>04. Ayo! Feat (TyFaizon)</h1>
            <p><strong>Produced By</strong> Paris Williams & Kaleab Samuel</p>
            <p><strong>Mixed and Mastered By</strong> Luke Petet</p>

            <div className="video-container2">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/YdIDh5OGu_Y?si=Iz-HF3KXve_tYQMF"
                title="Ayo"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </section>

          {/* Track 5 */}
          <section>
            <h1>05. Caterpillar</h1>
            <p><strong>Produced By</strong> Fa$hion Killa</p>
            <p><strong>Mixed and Mastered By</strong> Luke Petet</p>

            <div className="video-container2">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/ZyJcm_EFbjs?si=ULvyZyoAvOuNubOH"
                title="Caterpillar"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </section>

          {/* Track 6 */}
          <section>
            <h1>06. Uh Huh</h1>
            <p><strong>Produced By</strong> pig$</p>
            <p><strong>Additional Production by</strong> Paris Williams & Kaleab Samuel</p>
            <p><strong>Mixed and Mastered By</strong> Luke Petet</p>

            <div className="video-container2">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/3xdJv2JmY4o?si=F1y7MFdNHGeZJSxR"
                title="Uh Huh"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </section>

          {/* Track 7 */}
          <section>
            <h1>07. Bitter Sweet Heart Void</h1>
            <p><strong>Produced By</strong> Kaleab Samuel & Paris Williams</p>
            <p><strong>Mixed and Mastered By</strong> Luke Petet</p>

            <div className="video-container2">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/WNrwP8ULV5I?si=7aeVN0jHOYdzNepw"
                title="Bitter Sweet Heart Void"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </section>

          {/* Track 8 */}
          <section>
            <h1>08. Centered (Feat. TheBabeGabe)</h1>
            <p><strong>Produced By</strong> Fa$hion Killa</p>
            <p><strong>Additional Production by</strong> Paris Williams & Kaleab Samuel</p>
            <p><strong>Mixed and Mastered By</strong> Luke Petet</p>

            <div className="video-container2">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/Vl0mZA-GaDs?si=sKuZ085rd3tQvKX4"
                title="Centered"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </section>

          {/* Track 9 */}
          <section>
            <h1>09. Thank U</h1>
            <p><strong>Produced By</strong> Paris Williams</p>
            <p><strong>Mixed and Mastered By</strong> Luke Petet</p>


            <div className="video-container2">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/plQ0-kE7Vbw?si=UBaQFtA6-48tyhCZ"
                title="Thank U"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>

            <div className="mt-8 space-y-4">
      <p className="text-lg font-bold">Clean Version for DJs</p>
      <a
        href="https://mega.nz/folder/nAJwSaiI#yyHQINm3QP8ROd4YGFgRhw"
        className="download-button"
        download
      >
        Download
      </a>
    
</div>
          </section>
        </div>
      </div>
    
  );
}

export default Home;